<template>
    <div class="cart container">
        <div class="row">
            <div class="col">

                <h2>Jouw bestelling</h2>

                <CartOverview></CartOverview>

                <v-select
                    v-if="!isFoodAndRetailUser"
                    v-model="deliveryDate"
                    :items="deliveryDates"
                    label="Leverdatum"
                    item-text="text"
                    item-value="value"
                ></v-select>

                <p v-else>Levering binnen drie dagen.</p>

                <v-btn block
                       :disabled="!canPlaceOrder || isSubmittingOrder"
                       @click="submitOrder"
                       color="primary"
                >
                    <v-progress-circular
                        v-if="isSubmittingOrder"
                        indeterminate
                        size="20"
                        color="white"
                    />
                    <span v-else>Plaats bestelling</span>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import CartOverview from "../components/store/CartOverview";
import { DateTime } from 'luxon';

export default {
    name: "Cart",
    components: {
        CartOverview
    },
    data: () => ({
        deliveryDate: null,
        isSubmittingOrder: false,
    }),
    computed: {
        cart() { return this.$store.state.store.cart },
        displayPrices() { return this.$store.state.store.displayPrices },
        isCompleteCart() { return this.$store.getters.isCompleteCart },
        deliveryDates() {
            return this.$store.state.store.availableDeliveryDates.map(date => {
                return { text: DateTime.fromISO(date).toLocaleString(), value: date }
            })
        },
        canPlaceOrder() {
            if (!this.isCompleteCart) {
                return false;
            }
            if (this.deliveryDate === null && !this.isFoodAndRetailUser) {
                return false;
            }
            return true;
        },
        userRoles() {
            return this.$store.state.auth.user?.roles || [];
        },
        isFoodAndRetailUser() {
            return this.userRoles.includes("ROLE_INTERNAL_ORDER_USER_FOOD_RETAIL");
        }
    },
    methods: {
        submitOrder() {
            this.isSubmittingOrder = true;
            this.$store.dispatch('submitOrder', { deliveryDate: this.deliveryDate })
                .then(() => {
                    this.$router.push({name: 'OrderSuccess' })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackbar', { text: 'Door een technisch probleem kon je bestelling niet geplaatst worden.', color: 'error'})
                })
                .finally(() => {
                    this.isSubmittingOrder = false
                })
        }
    }
}
</script>

<style scoped>

</style>
